<template>
  <div>
    <v-data-table
      :headers="header"
      :items="attributeData"
      :options.sync="options"
      :items-per-page="options.itemsPerPage"
      :loading="loading"
      :server-items-length="itemsLength"
      :height="530"
      class="elevation-1 mb-5"
      hide-default-footer
      @update:page="optionsUpdated()"
      @update:sort-by="optionsUpdated()"
      @update:sort-desc="optionsUpdated()">
      <template #top>
        <v-toolbar flat>
          <v-toolbar-title>
            <span class="capitalize">
              Brands
            </span>
          </v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical />
          <v-spacer />
          <search-box
            v-model="search"
            @on-search="searchAttribute($event)" />
          <v-dialog
            v-model="dialog"
            max-width="500px">
            <template #activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                class="mb-1 ml-2"
                v-bind="attrs"
                v-on="on">
                New Item
              </v-btn>
            </template>
            <v-card>
              <validation-observer
                ref="attributeForm"
                tag="form"
                @submit.prevent="save()">
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row v-if="dialog">
                      <v-col cols="6">
                        <gw-text-field
                          v-model="formData.name"
                          :rules="textFieldRules"
                          :readonly="modalLoading"
                          label="Name" />
                      </v-col>
                      <v-col cols="6">
                        <gw-text-field
                          v-model="formData.uniqueCode"
                          :rules="textFieldRules"
                          :readonly="modalLoading"
                          label="Unique Code" />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="closeFormModal()">
                    Cancel
                  </v-btn>
                  <v-btn
                    :loading="modalLoading"
                    color="blue darken-1"
                    type="submit"
                    text>
                    Save
                  </v-btn>
                </v-card-actions>
              </validation-observer>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template
        #[`item.bcSync`]="{ item }"
        class="d-flex justify-center">
        <v-tooltip top>
          <template #activator="{ on, attrs }">
            <v-icon
              v-if="item.bcSync && item.bcSync.sync && item.bcSync.bcLog"
              v-bind="attrs"
              color="success"
              v-on="on">
              mdi-check-circle-outline
            </v-icon>
            <v-icon
              v-else
              v-bind="attrs"
              color="error"
              v-on="on">
              mdi-sync-alert
            </v-icon>
          </template>
          <span v-if="item.bcSync && item.bcSync.updatedAt">
            Last sync date {{ item.bcSync.updatedAt | dateTimeUserFormat() }}
          </span>
          <span v-else>
            Not sync yet
          </span>
        </v-tooltip>
      </template>
      <template #[`item.actions`]="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editItem(item)">
          mdi-pencil
        </v-icon>
        <gw-icon-hover
          small
          class="sku-table-bin"
          icon-name="mdi-delete"
          icon-hover="mdi-delete-empty"
          @click="removeAttribute(item)" />
      </template>
    </v-data-table>
    <v-pagination
      v-if="pageCount > 1"
      v-model="options.page"
      :length="pageCount"
      :disabled="loading"
      total-visible="7" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import ProductAttributeProvider from '@/resources/ProductAttributeProvider'
import SearchBox from '@/components/SearchBox.vue'

const ProductAttributeService = new ProductAttributeProvider()

export default {
  components: { SearchBox },
  data () {
    return {
      dialog: false,
      loading: false,
      itemsLength: 0,
      pageCount: 0,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['id'],
        sortDesc: [true]
      },
      header: [
        {
          text: 'Sync',
          value: 'bcSync',
          width: 80
        },
        {
          text: 'ID',
          value: 'id',
          width: 100
        },
        {
          text: 'Name',
          value: 'name',
          width: 200
        },
        {
          text: 'Unique Code',
          value: 'uniqueCode',
          width: 200
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          width: 60,
          align: 'end'
        }
      ],
      attributeData: [],
      isEdited: false,
      formData: {
        name: '',
        uniqueCode: ''
      },
      textFieldRules: {
        required: true
      },
      getting: false,
      modalLoading: false,
      isFocus: false,
      search: ''
    }
  },
  computed: {
    formTitle () {
      return this.isEdited ? 'Edit Item' : 'New Item'
    }
  },
  mounted () {
    if (this.search === '') {
      this.search = this.$route.query?.search || ''
    }
    this.getAttribute(this.options)
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setErrorPage: 'Components/setErrorPage',
      setModal: 'Components/setModal'
    }),
    searchAttribute (value) {
      this.options.page = 1
      this.search = value

      this.$router.push({
        name: this.$route.name,
        query: { search: value }
      }).catch(() => {})
      this.getAttribute(this.options)
    },
    async getAttribute (options) {
      if (!this.getting) {
        try {
          this.getting = true
          this.loading = true

          const { data } = await ProductAttributeService.getProductAttribute('brands', {
            ...options,
            search: this.search
          })

          this.itemsLength = data.total
          this.pageCount = data.pages
          this.attributeData = data.results
        } catch (error) {
          console.error('getAttribute: ', error)
          this.setErrorPage(error.code)
        } finally {
          this.loading = false
          this.getting = false
        }
      }
    },
    optionsUpdated () {
      this.getAttribute(this.options)
    },
    closeFormModal () {
      this.dialog = false
      this.isEdited = false
      this.formData = {
        name: '',
        uniqueCode: ''
      }
    },
    editItem (item) {
      this.isEdited = true
      this.formData = { ...item }
      this.dialog = true
    },
    async save () {
      this.modalLoading = true
      const vaid = await this.$refs.attributeForm.validate()

      if (vaid) {
        let message = 'created'

        try {
          if (this.isEdited) {
            await this.updateAttribute()
            message = 'updated'
          } else {
            await this.createAttribute()
          }

          this.setSnackbar({
            value: true,
            message: this.$t(`snackbar.Brands ${message}`),
            type: 'success'
          })

          this.closeFormModal()
          this.getAttribute(this.options)
        } catch (error) {
          console.error('saveAttribute', error)
          this.setSnackbar({
            value: true,
            message: `Error code ${error.code}: ${error.message}`,
            type: 'error'
          })
        }
      }

      this.modalLoading = false
    },
    async createAttribute () {
      const { data } = await ProductAttributeService.createProductAttribute('brands', this.formData)

      return !!data.id
    },
    async updateAttribute () {
      const { data } = await ProductAttributeService.updateProductAttribute({
        path: 'brands',
        id: this.formData.id
      }, this.formData)

      return !!data.id
    },
    async removeAttribute (item) {
      this.setModal({
        value: true,
        title: 'RemoveBrands',
        message: 'Do you want to remove the brands',
        confirmText: 'Remove',
        confirmType: 'error',
        cancelType: '',
        cancelText: 'Cancel',
        onConfirm: () => this.confirmRemoveAttribute(item.id)
      })
    },
    async confirmRemoveAttribute (id) {
      try {
        const { data } = await ProductAttributeService.deleteAttribute('brands', id)
        if (data.id && data.status === 'deleted') {
          this.setSnackbar({
            value: true,
            message: this.$t('snackbar.Brands removed'),
            type: 'success'
          })
          if (this.options.page > 1 && this.attributeData.length < 2) {
            this.options.page--
          } else {
            this.getAttribute(this.options)
          }
        } else {
          const error = {
            code: 400,
            message: 'Brands ID not found or status not change'
          }
          throw error
        }
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `Error code ${error.code}`,
          type: 'error'
        })
        console.error('confirmRemoveAttribute', error)
      }
    }
  }
}
</script>
